/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from 'gatsby';
import { Layout, SEO } from '@/components';

export const Head = ({ location }) => {
  const title = 'Discover the Njano Platform !';
  const description = 'Njano is a cloud-based flexible office management solution designed to manage coworking spaces';
  return <SEO title={title} description={description} location={location} />;
};

const ArticlePage = () => (
  <Layout dark logo>
    <div className="md:max-w-5xl container w-full px-16 pt-20 mx-auto bg-white shadow-lg">
      <div className="md:px-6 w-full px-4 text-xl leading-normal text-gray-800">
        <div className="font-content">
          <span className="text-primary md:text-sm space-y-8 text-base font-bold">
            <Link to="/" className="bg-secondary md:text-sm px-6 py-2 text-base font-bold text-white no-underline">
              &#8592; BACK TO HOME
            </Link>
            <h1 className="font-heading md:text-5xl pt-6 pb-2 text-3xl font-bold text-gray-900 break-normal">
              Discover the Njano Platform !
            </h1>
          </span>
        </div>
        <p className="py-6">
          Njano is a cloud-based flexible office management solution designed to manage coworking spaces. But not only
          that, Njano is an excellent solution to link startups, people and communities. The beta version of Njano was
          launched at the African Crossroads event last year. Since then, it has been refined and redefined to become
          what it is now. The platform is user-friendly and consists of a number of tools and features that are really
          helpful. With the current conditions, the African Crossroads event switched from physical to online. The use
          of a tool that would facilitate interactions between the communities seemed adequate, if not needed. Njano
          represented the perfect platform for this matter.
        </p>

        <p className="py-6">
          The menu contains five main functionalities that you can access: the dashboard, calendar, profile, space and
          community. The dashboard allows you to take a glance at the most important information like for example events
          to come, meetings, the lost and found section (for physical events) and many more which is really helpful to
          not miss out on anything. On the other hand, the calendar allows you to look in a more detailed fashion at the
          timeline and what is scheduled, like meetings, arrival of guests, events etc. “My space” contains different
          sub-categories to which you can access. For example, “events” allow you to take a look at the available events
          in details. You can access to information about the events whether they have already taken place, or are
          scheduled for the future. The other sub-categories are ones that are dedicated to coworking spaces mostly,
          like for example room booking, membership plans, claims, lost and found etc. Finally, the “My community”
          functionality is one that we find to be the most important on the platform. It allows the community to
          connect, interact and communicate which is the essence of African Crossroads. You can for example check your
          feed to see if there is any activity of the community or create a circle. Circles are spaces for people with
          similar interests in which you can share about certain topics or subjects. For instance, if your field of
          interest is entrepreneurship and you want to share and expand your ideas, you can create a circle for people
          with the same interests as you in order to discuss the subject which is the core of African Crossroads;
          discussing the future of Africa. Many circles have already been created for this matter like “Traditional
          music experience”, “African cosmologies and drinks” and many more. You can also find a space that is reserved
          for startups. In this sub-category, you can find a list of different startups that are associated to African
          Crossroads as well as Afkar, a partner incubator, for example. You can take look at a startup’s description,
          founders, e-mail and phone number, this allows you to enter in contact with the founders if you’re interested
          in its services, common project or partnership.
        </p>
        <p className="py-6">
          Njano opened up so many windows by giving the African Crossroads a platform that answers most of the needs of
          the event in these challenging times. In the meantime, we invite you to register and take a glance at the
          platform and get the chance to discover it. We are confident that the community of African Crossroads which is
          avid of interaction and communication will find this tool of a particular help. The event is still taking
          place in the shape of gatherings that occur in a monthly fashion. Stay tuned for more of African Crossroads!
        </p>
      </div>
    </div>
  </Layout>
);

export default ArticlePage;
